.primary-content{
    position: relative;
    display: grid;
    place-items: center;
    padding: 7.5rem;
}
.content{
    display: flex;
    align-items: center;
    gap: 2rem;
}
.primary-content::before{
    content: '';
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    filter: brightness(50%);
    background-image: url('../assets/images/2452-ai\ 1.png');
    background-repeat: no-repeat;
    background-position: 50% 60%;
    background-size: 40%;

}
@media (min-width: 2000px){
    .primary-content::before{
        background-size: 35%;
    }
}
@media (min-width: 2400px){
    .primary-content::before{
        background-size: 30%;
    }
}
.word{
    display: flex;
    gap: 1rem;
    color: white;
    font-size: min(200px, 12vw);
    font-family: "LoadingContent";
}
.word li:hover{
    transform: scale(1.2);
}

.arrow-button{
    display: grid;
    place-items: center;
    margin: auto auto 6rem auto;
    background: transparent;
    border: none;
    cursor: pointer;
}

@media(max-width: 50em){
    .primary-content{
        padding: 10rem 2rem;
        /* justify-content: space-between; */
    }

    .word{
        gap: 0.6rem;
       
    }


    .content{
        display: grid;
        place-items: center;
        gap: 0;
    }
    .word{
        font-size: 100px;
        height: fit-content;
    }
    .primary-content::before{
        background-size: min(110%, 85vw);
    }
}
@media(max-width: 30em){
    .primary-content::before{
        background-size: 110%;
    }
}

