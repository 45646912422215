.display-grid{
    display: grid;
}
.page-1{
    padding: 5rem 2rem 0 2rem;
    
}
.home-section-1{
    display: grid;
    gap: 1rem;
}
.home-section-1 h2{
    font-family: 'LoadingContent';
    background: linear-gradient(90deg, #00204E 0%, #CC00FF 93.78%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
.home-section-1 h1{
    font-family: 'PrimaryContent';
    font-size: 36px;
    font-weight: 700;
    color: white;
    line-height: 1;
}
.home-section-2{
    display: grid;
    place-items: center;
}
.home-section-2{
    --translate-x: calc(500px - 100%);
    min-width: 200%;
    transform: translateX(var(--translate-x));
}
@media(min-width: 30em){
    .home-section-2{
        transform: translateX(-200px);
    }
}
.button{
    font-family: 'Montserrat';
    font-size: 14px;
    padding: 1em 2em;
}
.btn-secondary{
    font-family: 'Montserrat';
    margin-left: 1.5rem;
    color: #61ecff;
    box-shadow: inset 0 0 0 3px currentColor;
    border: 0;
    border-radius: 10px;
    padding: 1em 2em;
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
    background-color: transparent;
    cursor: pointer;
}
.button-grp{
    margin-top: 2rem;
}
.bg-page1::before{
    content: '';
    width: 100%;
    height: 100%;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    background-image: url('../assets/images/Rectangle-page1-mobile.png');
    background-size: cover;
    background-repeat: no-repeat;
}
@media(min-width: 50em){
    .page-1{
        padding-top: 5em;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
        padding-bottom: 10rem;
        place-items: center;
    }
    .home-section-1{
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 0;
    }
    .home-section-2{
        min-width: 220%;
        transform: translateX(0);
    }
    .home-section-1 h1{
        font-size: 48px;
        line-height: 58px;
    }
    .home-section-1 h2{
        font-size: 16px;
    }
    .bg-page1::before{
        content: '';
        width: 100%;
        height: 100%;
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        background-color: white;
        background-image: url('../assets/images/Rectangle-page1.png');
        background-size: cover;
        background-repeat: no-repeat;
    }
    .button, .btn-secondary{
        font-size: 16px;
        padding: 1em 4em;
    }
}
.bg-page2::before{
    content: '';
    width: 100%;
    height: 100%;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
}
.gift-icon{
    color: #007988;
    transform: scale(1.45);
}
.home-section-3{
    display: grid;
    gap: 2rem;
    padding: 0 10%;
    text-align: center;
    color: rgba(3, 93, 104, 0.74);
}
.home-section-3 h1{
    font-family: 'PrimaryContent';
    font-size: 14px;
}
.home-section-3 p{
    font-family: 'Montserrat';
    font-size: 20px;
    font-weight: 600;
}

.home-section-4{
    display: grid;
    gap: 2rem;
    margin-top: 2rem;
    place-items: center;
}

.grid-2{
    gap: 2rem;
}
.section-4{
    position: relative;
    padding-bottom: 5rem;
}
/* .section-4::after{
    content: '';
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../assets/images/18-04-ai 1.png');
    background-repeat: no-repeat;
    background-position: 110% 90%;
    background-size: 70%;
} */
.fix-position{
    position:absolute;
    bottom: -250px;
    right: -30px;
    max-width: 80%;
}
@media(min-width: 50em){
    .fix-position{
        max-width: 50%;
    }
    .home-section-4{
        display: flex;
        justify-content: center;
        align-items: center;
   }
    .home-section-3 p{
        font-size: 48px;
    }
    .section-4{
        padding-top: 10rem;
        padding-bottom: 10rem;
    }
}
.bg-page3::before{
    content: '';
    width: 100%;
    height: 100%;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    background-image: url('../assets/images/Rectangle-page2-mobile.png');
    background-size: cover;
    background-repeat: no-repeat;
}
.home-section-3-img{
    max-width: 260px;
}
.home-section-5{
    display: grid;
    gap: 1rem;
}
.home-section-5 h1{
    font-family: 'PrimaryContent';
    font-size: 36px;
    color: white;
}
.home-section-5 p{
    font-family: 'Montserrat';
    font-size: 16px;
    color: white;
}
.read-more{
  background-color: transparent;
  border-width: 0;
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: inherit;
  font-weight: 600;
  line-height: inherit;
  padding: 0;
  width: fit-content;
  cursor: pointer;
  background: linear-gradient(90deg, #00204E 0%, #CC00FF 93.78%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.home-section-6 img{
    transform: scaleX(-1);
}
.container-mobile{
    --max-width: 1110px;
    --container-padding: 1rem;
    width: min(var(--max-width), 100% - (var(--container-padding) * 2));
    margin-inline: auto;
}
.section-5{
    padding: 20rem 2rem 0em 2rem;
    position: relative;
}
.home-section-6{
    transform: scale(1.5);
    padding-top: 4rem;
    padding-bottom: 4rem;
}
/* .home-section-6{
    position: absolute;
    top: 70%;
    left: 10%;
    min-width: 410px;
} */

#more {display: none;}
@media(min-width: 50em){
    .container-mobile{
        width: min(100%, 1110px);
    }
    .home-section-5 h1{
        max-width: fit-content;
        font-size: 46px;
    }
    .home-section-5 p{
        font-size: 24px
    }
    .home-section-6{
        position: static;
    }
    .home-section-6 img{
        transform: scaleX(1);
    }
    .section-5{
        grid-template-areas: 'two one one';
        place-items: center;
        padding-bottom: 5rem;
        padding-top: 15rem;
    }
    .home-section-5{
        max-width: 65ch;
        grid-area: one;
    }
    .home-section-6{
        grid-area: two;
    }

    .bg-page3::before{
        background-image: url('../assets/images/Rectangle-page2.png');
    }
    .home-section-6{
        transform: scale(1) translate(0, 0);
        width: 100%;
    }
}

.position-relative{
    position: relative;
}
.bg-page4::before{
    content: '';
    width: 100%;
    height: 100%;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url('../assets/images/tokenomic-bg-mobile.png');
    filter: brightness(40%);
}
.align-center{
    padding: 10rem 2rem 5rem 2rem;
    place-items: center;
}

.tokenomics-section {
    display: flex;
    align-items: center;
}

.content-wrapper{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 3em 2em;
    gap: 2rem;
    background-color: rgba(3, 93, 104, 1);
    border-radius: 20px;
}
.content-wrapper h1{
    color: white;
    font-family: 'PrimaryContent';
    font-size: 32px;
}
/* @keyframes slideInFromLef {
    0% {
      width: min(25ch, 25vw);
    }
    100% {
      width: 100%;
    }
  } */
.grid-box{
    display: grid;
    padding: 2em 2em 5em 2em;
    gap: 1rem;
    place-items: center;
    grid-template-areas: 'one'
    'two'
    'three';
    /* animation: 2s ease-out 0s 1 slideInFromLef;
    animation-fill-mode: forwards; */
}

.box-1{
    grid-area: 'one';
}
.box-2{
    grid-area: 'two';
}
.box-3{
    grid-area: 'three';
}



.box{
    width: min(25ch, 45vw);
    padding: 2em 2em;
    background: #027785;
    box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    display: grid;
    gap: 2rem;
    text-align: center;
}
.full-content-1{
    height: 100%;
    border-radius: 20px;
    box-shadow: 0 0 10px 1px rgba(3, 93, 104, 1);
    padding: 2em 2em;
    background-color: rgba(2, 119, 133, 1);;
    display: grid;
    gap: 2 rem;
    place-items: center;
}

.icon-center{
    margin: auto;
}
.main-content{
    display: grid;
    gap: 0.5rem;
    color: white;
    font-family: 'Montserrat';
}
.main-content h2{
    font-weight: 700;
    font-size: 16px;
}
.main-content p{
    font-weight: 600;
    font-size: 14px;
}
.full-content-1 h2{
    font-weight: 700;
    color: white;
    font-size: 16px;
}
.full-content-1 p{
    font-weight: 600;
    color: white;
    font-size: 14px;
}
@keyframes slideInFromLeft {
    0% {
      width: 0%;
    }
    70%{
       width: 80%; 
    }
    100% {
      width: 100%;
    }
  }
  
  .full-content-1 {  
    animation: 1s ease-out 0s 1 slideInFromLeft;
    animation-fill-mode: forwards;
  }

.full-content-main{
    display: flex;
    gap: 2rem;
}
.full-content-text{
    font-family: 'Montserrat';
}
.flex{
    display: flex;
    gap: 0.5rem;
    align-items: center;
    flex-direction: column;
}
.curve_footer{
    position:absolute;
    bottom:0;
    width: 100%;
    height: 400px;
    z-index: -1;
}
.full-content-para{
    font-weight: 400;
}

/* 

.page4_pc{
    display: block;
}

.page4_mobile{
    display: none;
} */



@media(max-width:1200px){
    .tokenomics-section {
        display: flex;
        align-items: center;
        padding: 5em 4% !important;
    }
}


@media (min-width: 50em){
    .grid-box{
        grid-template-areas:
         'one two three';
         grid-auto-flow: column;
         grid-auto-columns: 1fr;
        gap: 3rem;
    }
    .tokenomics-section {
        display: flex;
        align-items: center;
        padding: 5em 10em;
    }




    .content-wrapper{
        padding: 3rem 4rem;
        height: 35em;
    }
    .align-center{
        padding-bottom: 10rem;
    }
    .box{
        padding: 2em 1em;
        
    }
    .bg-page4::before{
        background-image: url('../assets/images/tokenomic-bg.png');
        background-size: 100%;
        background-repeat: no-repeat;
    }
    .full-content-1{
        grid-template-columns: 0.8fr 1fr;
        padding: 3em 3em;
        margin-left: 0;
        margin-right: auto;
        margin-bottom: 3em;
    }
    .token-icon{
        width: 50%;
        height: auto;
    }
    .full-content-1 h2{
        font-size: 20px;
    }
    .full-content-1 p{
        font-size: 16px;
        font-weight: 300;
    }
    .full-content-para{
        font-size: 16px;
        margin-top: 16px;
    }
    .flex{
        flex-direction: row;
    }

    
.curve_footer{
    position:absolute;
    bottom:0;
    width: 100%;
    height: 400px;
    z-index: -1;
}
}

.footer-1{
    padding: 5rem 2rem 0em 2rem;
    display: grid;
    gap: 1rem;
}
.footer-para{
    color: rgba(3, 93, 104, 1);
    font-family: 'PrimaryContent';
    font-size: 32px;
}


.social-icon{
    display: grid;
    gap: 1rem;
    margin-top: 50px;
}
.social-icon h2{
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 700;
    color: rgba(0, 169, 190, 1);
}
.social-icon-list{
    display: flex;
    gap: 1rem;
}
.bg-footer::before{
    content: '';
    width: 100%;
    height: 100%;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
}
.bg-footer::before{
    content: '';
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: 110% 65%, 10% 120%;
    background-size: 70%, 100%
}
.container-relative{
    position: relative;
}
.footer-2{
    place-items: center;
    margin-top: 5em;
    padding-bottom: 4em;
    gap: 2rem;
}

@media(min-width : 50em){
    .footer-para{
        font-size: 40px;
        max-width: 20ch;
    }
    .bg-footer::before{
        background-position: 110% -10%, 0% -140%;
        background-size: 60%, 100%;
    }
    .footer-1{
        padding: 5rem 2rem 0em 5rem;
    }
    .footer-section-1{
        display: flex;
        justify-content: space-between;
    }
    .footer-2{
        display: flex;
        justify-content: space-between;
        padding: 5em;
    }
    .container-mobile{
        width: 100%;
    }
    .logo p{
        font-size: 20px;
    }
}

