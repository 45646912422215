 .primary-header {
    z-index: 5;
    padding-top: 1rem;
    display: grid;
    place-items: center;
    font-family: 'Montserrat';
    position: sticky;
    top: 0;
  }
  .primary-navigation{
    display: flex;
    flex-grow: 1;
    align-items: center;
  }
  .primary-navigation a{
    text-decoration: none;
    color: white;
  }
  .nav-wrapper {
    padding: 0.75em 4em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 30px;
    transition: 0.5s;
  }
  
  .mobile-nav-toggle {
    display: none;
  }
  .mobile-visible{
    display: none;
  }
  .nav-list {
    display: flex;
    font-size: 16px;
    gap: clamp(1rem, 5vw, 3rem);
    font-weight: var(--fw-semi-bold);
    margin-left: auto;
    margin-right: auto;
  }
  
  .nav-list{
    color: white;
  }
  
  .nav-list :hover,
  .nav-list :focus {
    color: #007381;
    font-weight: bold;
  }
  .logo{
    display: flex;
    align-items: center;
    color: white;
}
.logo p{
  font-family: "LoadingContent";
  font-size: 28px;
}
.button {
    display: inline-flex; 
  }
  
.display-sm-hidden {
    display: none;
  }
.icon-close,
.icon-hamburger{
    color: white;
    z-index: 10;
}
.toggleDisplay{
  display: none;
}
.logo img{
  max-width: 50px;
 }

 .social-icon-list-nav{
  display: flex;
  margin-left: auto;
  margin-right: 2rem;
  gap: 1rem;
  justify-content: center;
}
@media(min-width: 50em){
    .display-md-inline-flex{
        display: inline-flex;
    }
}
  @media (max-width: 50em) {
    .primary-header {
      padding-top: 0;
      top: 0;
    }
    .mobile-visible{
      display: block;
    }
    .logo{
      display: none;
    }
    .mobile-width{
      width: 100% !important;
    }
    .nav-wrapper{
      background-color: #00A9BE !important;
      border-radius: 0;
      padding: 0.25em 1em;
    }
    .nav-list :hover,
    .nav-list :focus {
      color: #007381;
      font-weight: bold;
    }
    .primary-navigation {
      display: none;
      position: fixed;
      padding: 4em;
      inset: 1rem 0 auto;
      max-width: 17rem;
      margin-left: auto;
      background: #035D68;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      box-shadow: 0 0 0.75em rgb(0, 0, 0, 0.05);
      z-index: 6;
    }

    .logo{
      color: #fff !important;
    }
  
    .primary-header[data-overlay]::before {
      content: "";
      position: fixed;
      inset: 0;
      z-index: 5;
      /* height: 100vh; */
      background-image: linear-gradient(rgb(0 0 0 / 0.3), rgb(0 0 0 / 0.8));
    }
  
    .nav-list {
      display: grid;
      gap: var(--size-600);
      text-align: center;
      font-weight: var(--fw-bold);
    }
  
    .primary-navigation[data-visible] {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .toggleDisplay2[data-visible]{
      display: flex;
    }
    .toggleDisplay[data-visible]{
      display: block;
    }
  
    .mobile-nav-toggle {
      z-index: 10;
      display: block;
      cursor: pointer;
      background: transparent;
      border: 0;
      padding: 0.5em;
    }
    .social-icon-list-nav{
      gap: 1rem;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;
      margin-right: auto;
      /* margin-left: 10px; */
    }
    .nav-icon{
      transform: scale(1);
    }
    .btn-mobile{
      width: 110%;
      transform: translateX(-6px)
     }
  }

 .no-style{
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
 }



